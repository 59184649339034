<template>
  <app-page :title="$t('global.pageNotFound.title')">
    <template v-slot:content>
      <div class="message-box info">
        {{ $t('global.pageNotFound.error') }}
      </div>
      <br/>
      <app-button color="secondary" @click="redirectToOscar">
        {{ $t('global.navigation.backToHome') }}
      </app-button>
    </template>
  </app-page>
</template>

<script>
import AppPage from '@/components/layout/AppPage'
import AppButton from '@/components/buttons/AppButtonLayout'
import redirectToOscar from '@/utils/redirectToOscar'

export default {
  name: 'PageNotFound',
  components: { AppPage, AppButton },
  setup () {
    return { redirectToOscar }
  }
}
</script>

<style lang="scss">

</style>
