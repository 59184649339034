<template>
  <div class="app-page">
    <div class="loading-wrapper" v-if="loading">
      <simple-loading color="text"></simple-loading>
    </div>
    <div class="app-content-wrapper" v-else>
      <div class="app-content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleLoading from '@/components/SimpleLoading'

export default {
  name: 'AppPage',
  components: { SimpleLoading },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.app-page {
  min-height: calc(100vh - $header-height); // substract header height
  display: flex;
  flex-direction: column;
  .app-content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding-bottom: 24px;
    .app-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: $bp-desktop-big;
      padding: $space-s;
    }
  }
  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: $bp-tablet) {
  .app-page {
    min-height: calc(100vh - ($header-height + $footer-height)); // substract header height
    .app-content-wrapper {
      padding-top: $space-l;
    }
  }
}
</style>
